import Vue from "vue"
import DatePicker from 'vue2-datepicker'
import Loading from '@/components/widgets/Loading.vue'
import SortedTablePlugin from "vue-sorted-table"
import Paginate from "@/components/widgets/pagination.vue";

Vue.use(SortedTablePlugin, {
  ascIcon: '<i class="mdi mdi-arrow-down"></i>',
  descIcon: '<i class="mdi mdi-arrow-up"></i>'
})

export default {
  components: {
    Loading,
    DatePicker,
    Paginate
  },
  data: function() {
    return {
      loading: false, 
      busy: false,
      status: null,
      created: {
        start: null,
        end: null
      },
      duedate: {
        start: null,
        end: null
      },
      delivery: {
        start: null,
        end: null
      },
      purchaseOrderStatus: [
        { text: 'On hold', value: 'onhold' },
        { text: 'รออนุมัติ', value: 'wait-approve' },
        { text: 'อนุมัติแล้ว', value: 'approved' },
        { text: 'ไม่อนุมัติ', value: 'not-approved' },
        { text: 'สำเร็จ', value: 'success' }
      ],
      currentData: {},
      perPage: 6,
      sortBy: 'name',
      currentPage: 1,
      sortDesc: false,
      sortByFormatted: true,
      filterByFormatted: true,
      sortable: true,
      fields: [
        { 
          key: 'doc', 
          label: 'เลขที่เอกสาร', 
          thClass: 'font-weight-bold text-xs-1/2',
          tdClass: 'td-top text-xs-1/2',
          sortable: true 
        },
        { 
          key: 'purchasedOn', 
          label: 'วันที่สั่งซื้อ',
          thClass: 'font-weight-bold text-xs-1/2',
          tdClass: 'text-gray-400 td-top text-xs-1/2',
          sortable: true 
        },
        { 
          key: 'buyer', 
          label: 'ผู้ซิ้อ',
          thClass: 'font-weight-bold text-xs-1/2',
          tdClass: 'text-gray-400 td-top text-xs-1/2',
          sortable: true 
        },
        { 
          key: 'seller', 
          label: 'ผู้ขาย', 
          thClass: 'font-weight-bold text-xs-1/2',
          tdClass: 'text-gray-400 td-top text-xs-1/2',
          sortable: true 
        },
        { 
          key: 'product', 
          label: 'สินค้า',
          thClass: 'font-weight-bold text-xs-1/2',
          tdClass: 'text-gray-400 td-top text-xs-1/2',
          sortable: true 
        },
        { 
          key: 'qty', 
          label: 'จำนวน', 
          sortable: true, 
          thClass: 'font-weight-bold text-xs-1/2',
          tdClass: 'text-gray-400 td-top text-xs-1/2',
          class: 'text-right' 
        },
        { 
          key: 'nettotal', 
          label: 'ยอดรวมสุทธิ', 
          sortable: true, 
          thClass: 'font-weight-bold text-xs-1/2',
          tdClass: 'text-gray-400 td-top text-xs-1/2',
          class: 'text-right' 
        },
        { 
          key: 'dueDate', 
          label: 'วันที่ครบกำหนด', 
          thClass: 'font-weight-bold text-xs-1/2',
          tdClass: 'text-gray-400 td-top text-xs-1/2',
          sortable: true 
        },
        { 
          key: 'deliveryDate', 
          label: 'วันที่ส่งสินค้า', 
          thClass: 'font-weight-bold text-xs-1/2',
          tdClass: 'text-gray-400 td-top text-xs-1/2',
          sortable: true 
        },
        { 
          key: 'approve', 
          label: 'อนุมัติ', 
          thClass: 'font-weight-bold text-xs-1/2',
          tdClass: 'text-gray-400 td-top text-xs-1/2',
          sortable: true 
        },
        { 
          key: 'status', 
          label: 'สถานะ', 
          thClass: 'font-weight-bold text-xs-1/2',
          tdClass: 'text-gray-400 td-top text-xs-1/2',
          sortable: true 
        },
        { 
          key: 'action', 
          label: '#', 
          thClass: 'font-weight-bold text-xs-1/2',
          tdClass: 'text-gray-400 td-top text-xs-1/2'
        }
      ],
      items: [
        {
          _id: '123456',
          doc: 'PO-20221205465',
          purchasedOn: new Date(),
          buyer: 'กชมน',
          seller: 'มนชิรา',
          product: 'เหล็ก',
          nettotal: 5000,
          qty: 2,
          dueDate: new Date(),
          deliveryDate: new Date(),
          status: '<label class="badge badge-info">On hold</label>'
        }
      ]
    }
  },
  computed: {
    rows() {
      return this.items.length
    }
  },
  methods: {
    ondeleted(id) {
      this.$swal({
        width: "29rem",
        padding: '1.825em',
        html: '<div class="d-flex flex-row align-items-center">' +
          '<i class="fa fa-2x mr-3 fa-exclamation-triangle text-info"></i>'+
          '<div class="d-flex flex-column">'+
          '<span class="font-bold text-base py-2.5 text-left">คุณต้องการจะลบข้อมูลนี้หรือไม่?</span>'+
          '<span class="text-base text-left text-gray-500">หากคุณยืนยันแล้ว ข้อมูลจะไม่สามารถกู้คืนกลับมาได้?</span>'+
          '</div>'+
        '</div>',
        allowOutsideClick: false,
        focusConfirm: false,
        customClass: {
          popup: 'swal2-custom-rounded',
          closeButton: 'text-3xl swal2-custom-close',
          actions: 'justify-content-end',
          contant: 'd-flex flex-row justify-content-around px-2',
          title: 'd-none',
          confirmButton: "btn btn-info py-3 rounded-lg text-white font-prompt-light text-sm font-normal",
          cancelButton: 'btn btn-google py-3 rounded-lg text-white font-prompt-light text-sm font-normal'
        },
        cancelButtonColor: '#dc4a38',
        cancelButtonText: 'ยกเลิก',
        confirmButtonText: "ตกลง",
        showCancelButton: true,
        showCloseButton: true,
      })
    },
    oncreate() { },
    onupdate(id) { },
    onInfo(id) { },
    onInitData() {
      this.busy = true
      setTimeout(() => {
        this.busy = false
      }, 1000)
    }
  }
}
